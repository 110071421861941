import {useAuthStore} from "~/stores/auth.js";
import {usePreferenceStore} from "~/stores/preferences.js";
import useApiGatewayUrl from "~/composables/useApiGatewayUrl.js";
import {usePageState} from "../stores/page.js";

export default function useRefreshSubscription(body) {
    const {apiGatewayUrl} = useApiGatewayUrl();
    const loadedSubscription = ref(false);

    onMounted(async () => {
        const pageState = usePageState();
        const authStore = useAuthStore();
        try {
            const preferenceStore = usePreferenceStore();

            const {
                subStatus,
                level,
                frequency,
                name,
                pendingStatus,
                nextBillingDate,
                paid,
                upgrade
            } = await $fetch(apiGatewayUrl.value + "/stripe-validate-subscription", {
                method: 'POST', // Ensure you are using the correct method
                body: body,
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            document.body.classList.remove('unauthenticated');
            pageState.clearNotifications();

            authStore.setSubStatus(subStatus);
            authStore.setSubscriptionLevel(level);
            authStore.setSubscriptionFrequency(frequency);
            authStore.setPendingStatus(pendingStatus);
            authStore.setNextBillingDate(nextBillingDate);
            authStore.setPaidStatus(paid);
            authStore.setUpgradeStatus(JSON.stringify(upgrade));

            let user = {};
            if (authStore.user) {
                user = JSON.parse(atob(authStore.user));
                user['name'] = name;
            }else{
                user['name'] = name;
            }

            authStore.setUser(btoa(JSON.stringify(user)));
            authStore.authenticate(true);

            if (!paid) {
                pageState.setPayInvoiceNotification();
            }

            loadedSubscription.value = true;

            if (subStatus !== "active") {
                pageState.setSelectAPlanNotification();

                // //window.location.href = '/.netlify/functions/selectplan-redirect/';
            }
        } catch (e) {

            if (e.statusCode === 401) {
                document.body.classList.add('unauthenticated');
                pageState.logOutNotification();

                if(typeof authStore.clear === "function"){
                    authStore.clear();
                    authStore.setSubStatus('inactive');
                }
            }
        }
    });

    return loadedSubscription;

}